<template>
  <div class="b-order-form px-3">
    <div class="text-center">
      <loading :active="isLoading" :can-cancel="false" :is-full-page="false">
      </loading>
    </div>
    <div v-if="!isLoading">
      <div v-if="showWizard" class="wizard-wrapper">
        <div class="left-wrapper">
          <h2>{{ pageTitle }}</h2>
          <div class="progress-step">
            <div class="progress-percent" :style="{ width: currentStep / totalSteps * 100 + '%' }">
              Step {{ currentStep }} of {{ totalSteps }}...
            </div>
          </div>
          <ValidationObserver v-if="currentStep === 1" v-slot="{ handleSubmit, invalid }" ref="formStep1">
            <form @submit.prevent="handleSubmit(submit)">
              <div class="row step-container">
                <div class="col-md-12">
                  <text-input validate="required" name="Full Name" v-model="credentials.full_name"
                    :label="'auth.businessOrder.full_name' | translate" />
                </div>
                <div class="col-md-12">
                  <text-input validate="required" name="Company Name" v-model="credentials.company_name"
                    :label="'auth.businessOrder.company_name' | translate" />
                </div>
                <div class="col-md-12">
                  <text-input validate="required|email" name="email" v-model="credentials.email"
                    :label="'auth.businessOrder.email' | translate" />
                </div>
                <div class="col-md-12">
                  <text-input validate="required" :minlength="8" type="password" name="Password"
                    v-model="credentials.password" :label="'auth.businessOrder.password' | translate" vid="password" />
                </div>
                <div class="col-md-12">
                  <text-input validate="required|confirmed:password" :minlength="8" type="password"
                    name="Password Confirmation" v-model="credentials.password_confirmation"
                    :label="'auth.businessOrder.confirm_password' | translate" />
                </div>
                <div class="col-md-12">
                  <phone-number-input v-model="credentials.phone_number" name="Phone Number"
                    :label="'auth.businessOrder.phone_number' | translate" :onlyCountries="[]"
                    :disabledFetchingCountry="false" />
                </div>
                <div class="col-md-12">
                  <SelectInput :label="'auth.businessOrder.industry' | translate" v-model="credentials.industry"
                    name="industry" :options="industriesOptions" />
                </div>
              </div>
            </form>
          </ValidationObserver>
          <template v-if="currentStep === 2">
            <div v-if="plan">
              <div v-if="!isImmeidateCharge" class="no-payment-text text-center">
                <h6 class="font-weight-bold">No Payment Today</h6>
                <p>Your card will not be charged until your trial is over. <br />You can cancel at any time prior to the
                  charge with no payment.</p>
              </div>
              <AddCreditCard :addPaymentMethod="false" :eventBus="eventbus" :plan="plan" :skipTrial="forceNoTrial"
                v-on:done="handleVerifiedCard" v-on:failed="onCardFailed" :defaultCoupon="defaultCoupon" />
            </div>
            <ValidationObserver v-else v-slot="{ handleSubmit, invalid }" ref="formStep2">
              <form @submit.prevent="handleSubmit(submit)">
                <div class="row step-container">
                  <div class="col-md-12">
                    <SelectInput :label="'auth.businessOrder.total_contacts' | translate"
                      v-model="credentials.total_contacts" name="Total Contacts" :options="contactOptions" />
                  </div>
                  <div class="col-md-12">
                    <div class="row mt-2 mb-4">
                      <div class="col">
                        <label class="lelabel">{{ 'auth.businessOrder.departments' | translate }}</label>
                        <div class="checkbox abc-checkbox abc-checkbox-primary">
                          <input type="checkbox" name="departments" id="depSales" value="1"
                            v-model="credentials.departments">
                          <label for="depSales">
                            <span class="abc-label-text">Sales</span>
                          </label>
                        </div>
                        <div class="checkbox abc-checkbox abc-checkbox-primary">
                          <input type="checkbox" name="departments" id="devSupport" value="2"
                            v-model="credentials.departments">
                          <label for="devSupport">
                            <span class="abc-label-text">Support</span>
                          </label>
                        </div>
                        <div class="checkbox abc-checkbox abc-checkbox-primary">
                          <input type="checkbox" name="departments" id="depMarketing" value="3"
                            v-model="credentials.departments">
                          <label for="depMarketing">
                            <span class="abc-label-text">Marketing</span>
                          </label>
                        </div>
                        <div class="checkbox abc-checkbox abc-checkbox-primary">
                          <input type="checkbox" name="departments" id="depOther" value="4"
                            v-model="credentials.departments">
                          <label for="depOther">
                            <span class="abc-label-text">Other</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <text-input name="Total People" type="number" validate="required" v-model="credentials.total_people"
                      :label="'auth.businessOrder.total_people' | translate" />
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
          <div class="d-block">
            <div class="d-block">
              <Button type="button" :classes="`w-100 btn btn-primary btn-next ${nextButtonClass}`" :loading="processing"
                @click="handleNext()">
                {{ nextButtonLabel }}
              </Button>
            </div>
            <a v-if="showPrevious" href="javascript:;" class="mt-3 d-block prev-link" @click="handlePrev()"><i
                class="fa fa-arrow-left"></i> {{ 'auth.businessOrder.prev' | translate }}</a>
          </div>
        </div>
        <div class="right-wrapper">
          <h3 class="text-primary mt-3"><b>What's Included:</b></h3>
          <ul class="whats-included-ul">
            <li>
              <i class="fa fa-fw fa-check" style="color: rgb(59, 188, 103);"></i>
              <b>2-way Text Messaging:</b> Send and receive business texts either online or on your phone<br>
            </li>
            <li>
              <i class="fa fa-fw fa-check" style="color: rgb(59, 188, 103);"></i><b>Time-saving
                Automations:</b> Automatically respond to and sort incoming messages without having to do anything<br>
            </li>
            <li>
              <i class="fa fa-fw fa-check"></i><b>Shared Inbox:</b> Your entire team can see and respond to incoming
              messages (if desired)<br>
            </li>
            <li>
              <i class="fa fa-fw fa-check"></i><b>Mass Broadcasting:</b> Send campaigns and sales messages out that get a
              98% open rate on average<br>
            </li>
            <li>
              <i class="fa fa-fw fa-check"></i><b>Website Widget: </b>Convert more site visitors into leads with the
              ToroWave website widget
              <br>
            </li>
            <li>
              <i class="fa fa-fw fa-check"></i><b>Biannual Intensives:</b> Half-day sessions focused on
              new ways to close more deals &amp; scale quicker<br>
            </li>
          </ul>
          <div class="testimonial-wrapper mt-5">
            <div class="testimonial-item d-flex">
              <div class="img-wrapper">
                <img src="https://files.webtextchat.com/assets/dom1.webp" class="" height="100" width="100">
              </div>
              <div class="content-wrapper">
                <div class="" style="padding: 0 10px">
                  <span class="d-block mb-2" style="font-size: 20px; color: rgb(43, 151, 230);"><b>Dom, CEO</b></span>
                  <div class="d-block" style="font-size: 15px;">We went from $2M in revenues to over <b>$40M</b> in
                    revenues because of this tool!</div>
                </div>
              </div>
            </div>
          </div>
          <div class="guarantee-wrapper mt-5 d-flex flex-md-row flex-column">
            <div class="guarantee-item">
              <div class="">
                <img src="https://files.webtextchat.com/assets/grey-lock.webp" class="" width="" height="60">
              </div>
              <div class="" style="padding: 0 10px">
                <span class="title d-block mt-2"><b>Secure Processing</b></span>
                <div class="">Each order is processed through a secure, 256-bit encrypted payment processing gateway to
                  ensure your privacy.</div>
              </div>
            </div>
            <div class="guarantee-item">
              <div class="">
                <img src="https://files.webtextchat.com/assets/seal1.webp" class="" width="" height="60">
              </div>
              <div class="" style="padding: 0 10px">
                <span class="title d-block mt-2"><b>Money-Back <br />Guarantee</b></span>
                <div class="">If for any reason you feel you did not get adequate value for your money, let us know
                  within 14 days and we will refund the entire order.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div v-if="showOrderBump" class="col-md-12">
          <div class="content p-sm-4 text-center">
            <div class="h3 text-blue">
              <span class="font-weight-bold">WAIT!</span>
              <span class="font-weight-bold"> ✋ Before you get started... ✋</span>
            </div>
            <div class="h3 font-weight-light">
              Do you want our team of experts to personally set up your account for you?
            </div>
            <div class="h5 font-weight-light mt-4">
              Instead of you having to spend your time setting up, our team will:
            </div>
            <ul class="text-left mt-4">
              <li>Provide a 30 minute introductory call and Q&A session</li>
              <li>Procure and connect your new SMS number</li>
              <li>Set up the detailed settings in your account</li>
              <li>Add the most proven automations so they are ready to start using</li>
              <li>Create your website text widget and show you how to add to your site</li>
              <li>Provide a 1 hour training and onboarding session showing you system best practices</li>
            </ul>
            <hr class="ml-sm-5 mr-sm-5">
            <!-- <iframe src="https://player.vimeo.com/video/511322564?title=0&byline=0&portrait=0" width="640" height="360"
              frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> -->
            <div class="font-weight-light mt-4" style="font-size: 21px">
              Personalized White Glove Onboarding preventing you from spending hours setting up is <span
                class="price-span" :class="{ 'line-through': isWgoCoupon }">${{ formatPrice(wgoPrice) }}</span> <span
                v-if="isWgoCoupon" class="price-span">${{ formatPrice(this.wgoPriceDiscounted) }}</span> one-time
            </div>
            <div v-if="isWgoCoupon" class="text-center">( Coupon <b>{{ coupon.name }}</b> is applied )</div>
            <div>
              <Button type="button" :classes="`w-100 btn btn-lg btn-primary p-3 mt-4 font-weight-bold`"
                :loading="processing" @click="addOrderBump()">
                Add White Glove Onboarding so I don't have to set it all up
              </Button>
            </div>
            <div v-if="!isImmeidateCharge" class="h5 font-weight-light mt-4">
              You will not be charged until your trial period is over...
            </div>
            <div class="mt-5">
              <a class="text-blue h6" @click="skipOrderBump">
                <u>No thanks, I'd like to set everything up myself</u>
              </a>
            </div>
          </div>
        </div>
        <div v-else class="col-md-12 text-center">
          <h3 class="text-center font-weight-bold">
            {{ 'auth.businessOrder.step3_title' | translate }}
          </h3>
          <p>
            {{ 'auth.businessOrder.step3_p1' | translate }}
          </p>
          <p>
            {{ 'auth.businessOrder.step3_p2' | translate }}
          </p>
          <p>
            {{ 'auth.businessOrder.step3_p3' | translate }}
          </p>
          <div v-if="calendlyUrl" class="d-block w-100" style="margin-top: -40px">
            <div class="calendly-inline-widget" :data-url="calendlyUrl" style="min-width:320px;height:700px;"></div>
          </div>

          <div class="skip-wrapper mt-3">
            <a href="javascript:;" @click="onCalendarSkip">{{ 'auth.businessOrder.skip' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import AddCreditCard from './AddCreditCardBusiness.vue'
import Loading from 'vue-loading-overlay'

export default {
  components: {
    AddCreditCard,
    Loading
  },

  data() {
    return {
      isLoading: true,
      credentials: {
        email: '',
        company_name: '',
        full_name: '',
        password: '',
        phone_number: '',
        departments: [],
        total_people: 1,
        payment_method: undefined,
        coupon: undefined,
        plan: undefined
      },
      business: null,
      currentStep: 1,
      plan: null,
      contactOptions: [
        { value: 1, label: '0 - 500' },
        { value: 2, label: '501 - 2,000' },
        { value: 3, label: '2,001 - 5,000' },
        { value: 4, label: '5,001 - 20,000' },
        { value: 5, label: '20,000 - 100,000' },
        { value: 6, label: '100,001 +' },
      ],
      processing: false,
      totalSteps: 2,
      eventbus: new Vue(),
      forceNoTrial: false,
      showWizard: true,
      showOrderBump: false,
      registerToken: null,
      defaultCoupon: null,
      coupon: null,
    }
  },

  mounted() {
    this.forceNoTrial = this.$route.query.t === 'n'
    if (this.$route.query.email) {
      this.credentials.email = this.$route.query.email;
    }

    if (this.$route.query.name) {
      this.credentials.full_name = this.$route.query.name;
    }

    if (this.$route.query.coupon) {
      this.defaultCoupon = this.$route.query.coupon;
    }

    if (this.$route.query.plan) {
      this.getPlan()
    } else {
      this.isLoading = false;
    }
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    submit() {
    },

    skipOrderBump() {
      this.showOrderBump = false

      if (this.calendlyUrl && !this.plan && this.business.properties && this.business.properties && Array('silver', 'gold').includes(this.business.properties.level)) {
        this.addCalendarScript()
      } else {
        this.onCalendarSkip();
      }
    },

    addOrderBump() {
      if (this.registerToken) {
        this.processing = true
        const params = {
          token: this.registerToken,
          coupon: this.credentials.coupon,
        }
        this.$store.dispatch('auth/orderBump', params)
          .then((res) => {
            this.showOrderBump = false
            this.processing = false

            this.addCalendarScript()
          })
          .catch((e) => {
            this.processing = false
          })
      }
    },

    addCalendarScript() {
      if (this.calendlyUrl) {
        let script = document.createElement('script')
        script.src = "https://assets.calendly.com/assets/external/widget.js"
        script.async = "true"

        document.body.append(script)
      }
    },

    onCardFailed() {
      this.processing = false
    },

    handleVerifiedCard(arg) {
      const { id, coupon } = arg
      this.credentials.payment_method = id
      this.credentials.coupon = coupon ? coupon.id : undefined
      this.coupon = coupon;
      this.processing = false
      if (this.credentials.payment_method) {
        this.register();
      }
    },

    handlePrev() {
      this.currentStep--
    },

    async handleNext() {
      let valid = false;
      this.processing = true

      if (this.currentStep == 1) {
        valid = await this.$refs.formStep1.validate()
        let emailValid = await this.checkEmailUnique()
        if (!emailValid) {
          valid = false;
        }
      } else if (this.currentStep == 2) {
        if (this.plan) {
          valid = true
        } else {
          valid = await this.$refs.formStep2.validate()
        }
      }
      this.processing = false

      if (!valid)
        return;

      if (!this.plan && this.currentStep == 2 && !this.credentials.departments.length) {
        Vue.$toast.open({
          message: 'Please select at least one department available.',
          type: 'error',
        })
        return;
      }

      if (this.currentStep == 1) {
        let param = {
          company_name: this.credentials.company_name,
          name: this.credentials.full_name,
          email: this.credentials.email,
          phone: this.credentials.phone_number,
          plan: this.credentials.plan,
          is_main_agency: this.isMainAgency 
        }
        this.$store.dispatch('auth/postUserInfo', param)
      }

      if (this.currentStep < this.totalSteps) {
        this.currentStep++
      } else {
        this.processing = true
        if (this.plan) {
          this.eventbus.$emit('onValidateCard')
        } else {
          this.register();
        }
      }
    },

    register() {
      this.processing = true
      this.credentials.no_trial = this.forceNoTrial

      this.$store.dispatch('auth/businessOrder', this.credentials)
        .then((res) => {
          this.registerToken = res.token
          this.processing = false
          this.showWizard = false
          this.business = res.user.business;

          if (this.wgoPrice && this.plan) {
            this.showOrderBump = true
          } else {
            this.skipOrderBump()
          }
        })
        .catch((e) => {
          console.log(e);
          this.processing = false

          if (e.response.data && e.response.data.errors) {
            if (Object.keys(e.response.data.errors).length) {
              this.currentStep = 1
            }

            this.$nextTick(() => {
              this.$refs.formStep1.setErrors(e.response.data.errors)
            })
          }
        })
    },

    onCalendarSkip() {
      this.$router.push({ name: "auth.b.order-thankyou" })
    },

    goToDashboard() {
      this.$router.push({ name: "dashboard" })
    },

    getPlan() {
      this.$store.dispatch('subscription/getPlanByName', { name: this.$route.query.plan, restrict: true })
        .then((res) => {
          this.isLoading = false;
          this.plan = res.data
          this.credentials.plan = this.plan.id
        })
        .catch((err) => {
          window.location.href = '/business/register';
        })
    },

    checkEmailUnique() {
      if (this.credentials.email) {
        const params = {
          email: this.credentials.email
        }
        return this.$store.dispatch('auth/checkEmail', params)
          .then((res) => {
            return Promise.resolve(true);
          })
          .catch((e) => {
            if (e.response.data && e.response.data.errors) {
              this.$refs.formStep1.setErrors(e.response.data.errors)
            }
            return Promise.resolve(false);
          })
      } else {
        return Promise.resolve(false);
      }
    },
  },

  computed: {
    isMainAgency() {
      return this.$store.state.app.sitedata.isMainAgency
    },

    isImmeidateCharge() {
      return this.plan && (!this.plan.trial_period_days || this.forceNoTrial)
    },

    wgoPrice() {
      return this.sitedata && this.sitedata.wgoPriceAmount
    },

    isWgoCoupon() {
      return this.coupon && this.coupon.meta && this.coupon.meta['WGO'] && this.coupon.meta['WGO'] === 'True'
    },

    wgoPriceDiscounted() {
      let res = this.wgoPrice;
      if (this.isWgoCoupon) {
        if (this.coupon.amount_off)
          res = this.wgoPrice - this.coupon.amount_off / 100
        else if (this.coupon.percent_off)
          res = this.wgoPrice * (100 - this.coupon.percent_off) / 100
      }
      return res
    },

    pageTitle() {
      let title = `Start Your Account!`;
      if (this.plan && this.plan.trial_period_days && !this.forceNoTrial)
        title = `Start FREE ${this.plan.trial_period_days} Day Trial!`;
      if (this.currentStep === 1) {
        title = 'Start Your Free Trial Now'
      }
      return title
    },

    agencyTitle() {
      return this.sitedata && this.sitedata.title
    },

    supportEmail() {
      return this.sitedata && this.sitedata.supportEmail
    },

    supportPhone() {
      return this.sitedata && this.sitedata.supportPhone
    },

    nextButtonLabel() {
      let label = 'Next'
      if (this.currentStep === this.totalSteps) {
        if (this.plan && this.plan.trial_period_days && !this.forceNoTrial)
          label = 'Start My Free Trial Now'
        else
          label = 'Start My Account Now'
      }
      return label
    },

    nextButtonClass() {
      return this.currentStep === this.totalSteps ? 'btn-success btn-register' : ''
    },

    showPrevious() {
      return this.currentStep != 1
    },

    industriesOptions() {
      return this.$store.state.app.industries
    },

    loading() {
      return this.$store.state.auth.loading
    },

    sitedata() {
      return this.$store.state.app.sitedata
    },

    calendlyUrl() {
      return this.sitedata && this.sitedata.calendlyUrl
    },
  },
}
</script>
  
<style lang="scss">
.b-order-form {
  margin: 0 auto;
  color: #070707;
  margin: 30px 0px 50px;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
    padding: 0!important;
  }

  .wizard-wrapper {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    .left-wrapper {
      flex: 0 0 50%;
      background-color: #eef3fa;
      padding: 40px 30px;
      @include media-breakpoint-down(sm) {
        padding: 30px 20px;
      }
    }

    .right-wrapper {
      flex: 1 0;
      padding-left: 50px;

      @include media-breakpoint-down(sm) {
        padding-left: 10px;
        padding-right: 10px;
        .text-primary {
          text-align: center;
        }
      }

      ul.whats-included-ul {
        list-style: none;
        font-size: 17px;

        li {
          margin-bottom: 5px;

          i {
            position: absolute;
            margin-left: -35px;
            margin-top: 5px;
            color: rgb(59, 188, 103);
          }
        }
      }

      .testimonial-wrapper {
        .testimonial-item {
          .img-wrapper {
            flex: 0 0 100px;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
            img {
              object-fit: cover;
            }
          }
        }
      }

      .guarantee-wrapper {
        .guarantee-item {
          flex: 0 0 50%;
          text-align: center;

          span.title {
            font-size: 18px;
            min-height: 58px;
            @include media-breakpoint-down(sm) {
              min-height: auto;
              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .no-payment-text {
    p {
      font-style: italic;
    }
  }

  .price-span {
    font-weight: bold;
    font-size: 21px;
    display: inline-block;
    padding: 0 5px;
  }

  .line-through {
    text-decoration: line-through;
  }

  .input,
  .multiselect__tags {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    height: 42px;
  }

  .vue-tel-input {
    border-radius: 5px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    border: none;
    height: 46px;

    input {
      height: 100%;
      line-height: 100%;
      border: none;
      margin-top: 0px;
    }
  }

  h2 {
    text-align: center;
  }

  .link {
    color: #3578c6;
  }

  .btn-next {
    flex: 1 0 auto;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .btn-register {
    font-size: 25px;
    font-weight: 600;
    padding: 14px;
  }

  .prev-link {
    color: #1a76d2;
  }

  .progress-step {
    background: #f5f5f5;
    border-radius: 20px;
    margin-bottom: 40px;
    color: #FFF;
    position: relative;
    box-shadow: 0px 0px 2px #a4a3a3;
    overflow: hidden;
    height: 36px;

    .progress-percent {
      background: #1a76d2;
      text-align: center;
      font-weight: 600;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      line-height: 36px;
    }
  }

  .vuestic-date-picker {
    display: none;
  }

  .skip-wrapper {
    a {
      color: #1a76d2;
    }
  }

  .ready-dive-in {
    p {
      font-size: 18px;
    }
  }
}
</style>
  